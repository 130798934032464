<div [formGroup]="formGroup">
    <mat-form-field class="flex-auto w-full gt-xs:pr-3">
        <mat-label>{{ label }}</mat-label>
        <mat-select
            [formControlName]="controlName"
            placeholder="Pesquisar..."
            #singleSelect>
            <mat-option>
                <ngx-mat-select-search
                    [formControl]="farmServerSideFilteringCtrl"
                    [searching]="searching"
                    placeholderLabel="Procurar fazenda..."
                    noEntriesFoundLabel="fazenda não encontrada"></ngx-mat-select-search>
            </mat-option>
            <mat-option
                class="mt-2 border-b"
                *ngFor="let farm of filteredServerSideFarms | async"
                [value]="farm.id">
                <div class="font-medium leading-5">
                    {{
                        farm.identity_name
                            ? farm.identity_name + ' - ' + farm.farm_name
                            : farm.farm_name
                    }}
                </div>
                <div class="leading-5 text-secondary">
                    {{
                        farm.company_type == '0'
                            ? farm.company_name + ' - '
                            : ''
                    }}{{ farm.city }} / {{ farm.state }}
                </div>
            </mat-option>
        </mat-select>
    </mat-form-field>
</div>
