<div [formGroup]="formGroup">
    <mat-form-field class="flex-auto w-full gt-xs:pr-3">
        <mat-label>{{ label }}</mat-label>
        <mat-select
            [formControlName]="controlName"
            placeholder="Pesquisar..."
            #singleSelectUser>
            <mat-option>
                <ngx-mat-select-search
                    [formControl]="userServerSideFilteringCtrl"
                    [searching]="searching"
                    placeholderLabel="Procurar usuários..."
                    noEntriesFoundLabel="usuário não encontrado"></ngx-mat-select-search>
            </mat-option>

            <mat-option *ngIf="showNone" class="mt-2 border-b" [value]="null">
                <div class="flex flex-col sm:flex-row sm:items-center py-6">
                    <div class="flex items-center">
                        <div
                            class="flex flex-0 items-center justify-center w-10 h-10 rounded-full overflow-hidden">
                            <ng-container>
                                <div
                                    class="flex items-center justify-center w-full h-full rounded-full text-lg uppercase bg-gray-200 text-gray-600 dark:bg-gray-700 dark:text-gray-200">
                                    <mat-icon
                                        class="ml-4 icon-size-2 text-hint cursor-pointer"
                                        [svgIcon]="
                                            'heroicons_outline:ban'
                                        "></mat-icon>
                                </div>
                            </ng-container>
                        </div>
                        <div class="ml-4">
                            <div class="font-small">Nenhum</div>
                        </div>
                    </div>
                </div>
            </mat-option>

            <mat-option
                class="mt-2 border-b"
                *ngFor="let user of filteredServerSideUsers | async"
                [value]="user.id">
                <div class="flex flex-col sm:flex-row sm:items-center py-6">
                    <div class="flex items-center">
                        <div
                            class="flex flex-0 items-center justify-center w-10 h-10 rounded-full overflow-hidden">
                            <ng-container *ngIf="user.photo">
                                <img
                                    class="object-cover w-full h-full"
                                    [src]="user.photo"
                                    [alt]="user.firtsname" />
                            </ng-container>
                            <ng-container *ngIf="!user.photo">
                                <div
                                    class="flex items-center justify-center w-full h-full rounded-full text-lg uppercase bg-gray-200 text-gray-600 dark:bg-gray-700 dark:text-gray-200">
                                    <mat-icon
                                        class="ml-4 icon-size-2 text-hint cursor-pointer"
                                        [svgIcon]="
                                            'mat_outline:no_photography'
                                        "></mat-icon>
                                </div>
                            </ng-container>
                        </div>
                        <div class="ml-4">
                            <div class="font-medium">
                                {{ user.firstname }} {{ user.lastname }}
                            </div>
                        </div>
                    </div>
                </div>
            </mat-option>
        </mat-select>
    </mat-form-field>
</div>
