import { FuseConfigService } from '@fuse/services/config';
import { Injectable } from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthService } from 'app/core/auth/auth.service';
import { AuthUtils } from 'app/core/auth/auth.utils';
import { environment } from 'environments/environment';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private host: string;

  constructor(
    private _authService: AuthService,
    private _fuseConfigService: FuseConfigService
  ) {
    this._fuseConfigService.config$.subscribe(config => {
      this.host = environment.authHost;
    });
  }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // Clone the request object
    let newReq = req.clone();

    // Request
    //
    // If the access token didn't expire, add the Authorization header.
    // We won't add the Authorization header if the access token expired.
    // This will force the server to return a "401 Unauthorized" response
    // for the protected API routes which our response interceptor will
    // catch and delete the access token from the local storage while logging
    // the user out from the app.
    if (
      this._authService.accessToken !== undefined &&
      !AuthUtils.isTokenExpired(this._authService.accessToken)
    ) {
      newReq = req.clone({
        headers: req.headers
          .set('requestfrom', 'admin')
          .set('Authorization', 'Bearer ' + this._authService.accessToken),
      });
    }

    // Response
    return next.handle(newReq).pipe(
      catchError(error => {
        // Catch "401 Unauthorized" responses
        if (error instanceof HttpErrorResponse && error.status === 401) {
          // Sign out
          this._authService.signOut();

          // Reload the app
          if (error.url !== this.host + '/auth/login') {
            location.reload();
          }
        }

        return throwError(error);
      })
    );
  }
}
