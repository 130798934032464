<div [formGroup]="formGroup">
    <mat-form-field class="flex-auto w-full gt-xs:pr-3">
        <mat-label>{{ label }}</mat-label>
        <mat-select
            [formControlName]="controlName"
            placeholder="Pesquisar..."
            multiple>
            <mat-option>
                <ngx-mat-select-search
                    [formControl]="farmServerSideFilteringCtrl"
                    [searching]="searching"
                    placeholderLabel="Procurar fazenda..."
                    noEntriesFoundLabel="fazenda não encontrada"></ngx-mat-select-search>
            </mat-option>
            <mat-select-trigger *ngIf="formGroup.get('farm').value">
                <!--formGroup.get('farm').value?.[0]-->
                {{ getFarmIndex(formGroup.get('farm').value[0]) || '' }}
                <span
                    *ngIf="(formGroup.get('farm').value.length || 0) > 1"
                    class="text-md text-gray-500">
                    (+{{ (formGroup.get('farm').value.length || 0) - 1 }}
                    {{
                        formGroup.get('farm').value.length === 2
                            ? 'outro'
                            : 'outros'
                    }})
                </span>
            </mat-select-trigger>
            <mat-option
                *ngFor="let farm of filteredServerSideFarms | async"
                [value]="farm.id">
                <div class="font-medium leading-5">
                    {{
                        farm.identity_name
                            ? farm.identity_name + ' - ' + farm.farm_name
                            : farm.farm_name
                    }}
                </div>
                <div class="leading-5 text-secondary">
                    {{
                        farm.company_type == '0'
                            ? farm.company_name + ' - '
                            : ''
                    }}{{ farm.city }} / {{ farm.state }}
                </div>
            </mat-option>
            <!--<mat-option class="mt-2 border-b" *ngFor="let farm of filteredServerSideFarms | async" [value]="farm.id">
                <div class="font-medium leading-5">{{farm.identity_name ? farm.identity_name + ' - ' + farm.farm_name:farm.farm_name}}</div>
                <div class="leading-5 text-secondary"> {{(farm.company_type == '0')? farm.company_name + ' - ' :''}}{{farm.city}} / {{farm.state}}</div>
			</mat-option>-->
        </mat-select>
    </mat-form-field>
</div>
