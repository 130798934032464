import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { NavigationService } from 'app/core/navigation/navigation.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AuthRoles implements CanActivate {
  constructor(private navigation: NavigationService, private router: Router) {}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | boolean
    | UrlTree
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree> {
    const userRole = localStorage.getItem('role');
    if (
      userRole.includes('admin') ||
      userRole.includes('coordenador') ||
      userRole.includes('solubio') ||
      userRole.includes('franchise') ||
      userRole.includes('laboratorio')
    ) {
      return true;
    }

    this.router.navigate(['']);
    console.log('não autorizado');
    alert('Você não tem autorização para acessar essa página');
    return false;
  }
}
