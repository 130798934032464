import { Regional } from '../../../../core/models/squads/territory.model';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Territory } from 'app/core/models/squads/territory.model';
import { take, takeUntil } from 'rxjs/operators';
import { TerritoryService } from '../../../../core/services/squads/territory.service';
import { Component, OnInit, Inject } from '@angular/core';
import { DashboardService } from '../../../../core/services/admin/dashboard.service';
import * as moment from 'moment';
import { FuseConfirmationService } from '@fuse/services/confirmation';

@Component({
  selector: 'diaries-modal',
  templateUrl: './diaries-modal.component.html',
  styleUrls: ['./diaries-modal.component.scss'],
})
export class DiariesModalComponent implements OnInit {
  user: any;
  title: string;
  diaries: any[];

  constructor(
    public matDialogRef: MatDialogRef<DiariesModalComponent>,
    public matDialog: MatDialog,
    private _fuseConfirmationService: FuseConfirmationService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit() {
    this.user = this.data.user;
    this.title =
      'Visitas efetuadas por ' +
      this.user.firstname +
      ' ' +
      (this.user.lastname || '');
    this.diaries = this.data.diaries;
  }

  cancel(): void {
    this.matDialogRef.close();
  }

  formatDate(value: string): string {
    return moment(value).format('DD/MM/YYYY');
  }

  formatTime(value: string): string {
    return moment(value).format('HH:mm');
  }

  openDetails(item: any) {
    const confirmation = this._fuseConfirmationService.open({
      title: 'Observação visita #' + item.id,
      message: item.description,
      icon: {
        show: false,
      },
      actions: {
        confirm: {
          show: true,
          label: 'Ok',
          color: 'primary',
        },
        cancel: {
          show: false,
        },
      },
    });
  }
}
