<div class="flex flex-col max-w-240 md:min-w-160 -m-6 modal-container">
    <!-- Header -->
    <div
        class="flex flex-0 items-center justify-between h-16 pr-3 sm:pr-5 pl-6 sm:pl-8 bg-primary text-on-primary">
        <div class="text-lg font-medium">{{ title }}</div>
        <button mat-icon-button (click)="cancel()" [tabIndex]="-1">
            <mat-icon
                class="text-current"
                [svgIcon]="'heroicons_outline:x'"></mat-icon>
        </button>
    </div>

    <!-- Header -->
    <div
        class="shadow text-md font-semibold text-secondary bg-gray-50 dark:bg-black dark:bg-opacity-5 farm-list-header">
        <div class="truncate flex-1 text-center">Código</div>
        <div class="truncate">Nome da Fazenda</div>
        <div class="truncate">Nome do Proprietário</div>
    </div>

    <!--Order List -->

    <!-- Rows -->
    <div class="farm-list">
        <div *ngFor="let farm of farms" class="farm-list-item">
            <div class="truncate flex-1 text-center">
                {{ farm.id }}
            </div>

            <div class="truncate">
                {{ farm.farm_name }}
            </div>

            <div class="truncate">
                {{ farm.company_name }}
            </div>
        </div>
    </div>
</div>
