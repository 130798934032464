import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'maxLength',
})
export class MaxLengthPipe implements PipeTransform {
  transform(value: string, maxLength: number, endTxt = '...'): any {
    if (maxLength == null) return value;

    return value.length > maxLength
      ? value.substring(0, maxLength) + endTxt
      : value;
  }
}
