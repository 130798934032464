import { UserService } from 'app/core/services/auth/user.service';
import { AlertService } from './../../alert/alert.service';
import { FuseConfirmationService } from '@fuse/services/confirmation';
import { FuseConfigService } from './../../../../@fuse/services/config/config.service';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, ReplaySubject, BehaviorSubject, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { User } from 'app/core/models/auth/user.model';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root',
})
export class UsersService {
  private _user: ReplaySubject<User> = new ReplaySubject<User>(1);
  private _users: BehaviorSubject<User[] | null> = new BehaviorSubject(null);
  private host: string;
  private userList: User[] = [];

  constructor(
    private _httpClient: HttpClient,
    private _fuseConfigService: FuseConfigService,
    private _fuseConfirmationService: FuseConfirmationService,
    private _alertService: AlertService,
    private _authUserService: UserService
  ) {
    this._fuseConfigService.config$.subscribe(config => {
      this.host = environment.authHost;
    });
  }

  get user$(): Observable<User> {
    return this._user.asObservable();
  }

  get users$(): Observable<User[]> {
    return this._users.asObservable();
  }

  get(userId: string): Observable<User> {
    return this._httpClient
      .get<User>(this.host + '/api/v1/user/' + userId)
      .pipe(
        tap(user => {
          this._user.next(user);
        })
      );
  }

  getUsers(no_pict = false): Observable<User[]> {
    return this._httpClient
      .get<User[]>(
        this.host +
          '/api/v1/user?orderby=firstname&order=asc' +
          (no_pict ? '&no_pict=true' : '')
      )
      .pipe(
        tap(users => {
          users = users.sort(function (a, b) {
            return a.firstname.localeCompare(b.firstname);
          });
          this.userList = users;
          this._users.next(users);
        })
      );
  }

  getUsersMemory(): Observable<User[]> {
    if (this.userList.length > 0) {
      return of(this.userList);
    }
    return this.getUsers(true);
  }

  updateUser(userId: string, user: User): Observable<any> {
    return this._httpClient
      .put<User>(this.host + '/api/v1/user/' + userId, user)
      .pipe(
        map(response => {
          this.userList = [];
          this._user.next(response);
        })
      );
  }

  changePassword(data: any): Observable<any> {
    return this._httpClient.post<any>(this.host + '/auth/changepass', data);
  }

  createUser(user: any): Observable<any> {
    return this._httpClient.post<any>(this.host + '/auth/register', user).pipe(
      map(resp => {
        this.userList = [];
        return resp;
      })
    );
  }

  deleteUser(userId: string, userName: string): void {
    // Open the confirmation dialog
    const confirmation = this._fuseConfirmationService.open({
      title: 'Excluir usuário',
      message: 'Deseja realmente excluir o usuário ' + userName + '?',
      actions: {
        confirm: {
          label: 'Excluir',
        },
        cancel: {
          label: 'Cancelar',
        },
      },
    });

    // Subscribe to the confirmation dialog closed action
    confirmation.afterClosed().subscribe(result => {
      // If the confirm button pressed...
      if (result === 'confirmed') {
        return this._httpClient
          .delete<any>(this.host + '/api/v1/user/' + userId)
          .subscribe(
            () => {
              this._alertService.showSuccess(
                'Excluir Usuário',
                'Usuário excluído com sucesso!'
              );
              // TODO: Em vez de carregar todos os usuários novamente, deve remover do array
              this.userList = [];
              this._authUserService.getUsers(null).subscribe();
            },
            e => {
              this._alertService.showError(
                'Excluir usuário',
                'Erro ao excluir o usuário ' + userName + '!'
              );
            }
          );
      }
    });
  }

  getUserName(id: string): string {
    if (!this.userList) {
      return '';
    }
    return this.userList
      .filter(f => f.id.toString() == id)
      .map(fa => fa.firstname + ' ' + fa.lastname)[0];
  }
}
