<!-- Button -->
<button mat-icon-button [matMenuTriggerFor]="userActions">
    <span class="relative">
        <div
            class="relative flex items-center justify-center w-10 h-10 rounded-full overflow-hidden ring-4 ring-bg-card">
            <container *ngIf="user.photo" class="object-cover w-full h-full">
                <img
                    style="
                        object-fit: cover;
                        position: relative;
                        height: 100%;
                        margin-left: auto;
                        margin-right: auto;
                        top: 50%;
                        -ms-transform: translateY(-50%);
                        transform: translateY(-50%);
                    "
                    [src]="user.photo" />
            </container>
            <mat-icon
                *ngIf="!user.photo"
                [svgIcon]="'heroicons_outline:user-circle'"></mat-icon>
        </div>
    </span>
</button>

<mat-menu [xPosition]="'before'" #userActions="matMenu">
    <button mat-menu-item>
        <span class="flex flex-col leading-none">
            <span>Conta Solubio</span>
            <span class="mt-1.5 text-md font-medium">{{ user.email }}</span>
        </span>
    </button>
    <mat-divider class="my-2"></mat-divider>
    <button mat-menu-item>
        <a style="width: 100%" [routerLink]="['./settings/profile']">
            <mat-icon [svgIcon]="'heroicons_outline:user-circle'"></mat-icon>
            <span>Perfil</span>
        </a>
    </button>
    <mat-divider class="my-2"></mat-divider>
    <button mat-menu-item (click)="signOut()">
        <mat-icon [svgIcon]="'heroicons_outline:logout'"></mat-icon>
        <span>Logout</span>
    </button>
</mat-menu>

<mat-menu class="user-status-menu" #userStatus="matMenu">
    <button mat-menu-item (click)="updateUserStatus('online')">
        <span class="w-4 h-4 mr-3 rounded-full bg-green-500"></span>
        <span>Online</span>
    </button>
    <button mat-menu-item (click)="updateUserStatus('away')">
        <span class="w-4 h-4 mr-3 rounded-full bg-amber-500"></span>
        <span>Away</span>
    </button>
    <button mat-menu-item (click)="updateUserStatus('busy')">
        <span class="w-4 h-4 mr-3 rounded-full bg-red-500"></span>
        <span>Busy</span>
    </button>
    <button mat-menu-item (click)="updateUserStatus('not-visible')">
        <span class="w-4 h-4 mr-3 rounded-full bg-gray-400"></span>
        <span>Invisible</span>
    </button>
</mat-menu>
