import { AuthRoles } from './core/auth/guards/auth-roles.guard';
import { Route } from '@angular/router';
import { InitialDataResolver } from 'app/app.resolvers';
import { AuthGuard } from 'app/core/auth/guards/auth.guard';
import { LayoutComponent } from 'app/layout/layout.component';

// @formatter:off
// tslint:disable:max-line-length
export const appRoutes: Route[] = [
  // Redirect empty path to '/example'
  { path: '', pathMatch: 'full', redirectTo: 'dashboard' },

  // Redirect signed in user to the '/sample'
  //
  // After the user signs in, the sign in page will redirect the user to the 'signed-in-redirect'
  // path. Below is another redirection for that path to redirect the user to the desired
  // location. This is a small convenience to keep all main routes together here on this file.
  { path: 'signed-in-redirect', pathMatch: 'full', redirectTo: 'dashboard' },

  // Auth routes for guests
  {
    path: '',
    // canActivate: [NoAuthGuard],
    // canActivateChild: [NoAuthGuard],
    component: LayoutComponent,
    data: {
      layout: 'empty',
    },
    children: [
      {
        path: 'forgot-password',
        loadChildren: () =>
          import(
            'app/modules/auth/forgot-password/forgot-password.module'
          ).then(m => m.AuthForgotPasswordModule),
      },
      {
        path: 'reset-password/:token',
        loadChildren: () =>
          import('app/modules/auth/reset-password/reset-password.module').then(
            m => m.AuthResetPasswordModule
          ),
      },
      {
        path: 'sign-in',
        loadChildren: () =>
          import('app/modules/auth/sign-in/sign-in.module').then(
            m => m.AuthSignInModule
          ),
      },
      {
        path: 'sign-up',
        loadChildren: () =>
          import('app/modules/auth/sign-up/sign-up.module').then(
            m => m.AuthSignUpModule
          ),
      },
    ],
  },

  // Timeline
  {
    path: '',
    component: LayoutComponent,
    data: {
      layout: 'empty',
    },
    children: [
      {
        path: 'timeline',
        loadChildren: () =>
          import('app/modules/timeline/timeline.module').then(
            m => m.TimelineModule
          ),
      },
      {
        path: 'survey',
        loadChildren: () =>
          import('app/modules/admin/survey/survey.module').then(
            m => m.SurveyModule
          ),
      },
    ],
  },

  // Auth routes for authenticated users
  {
    path: '',
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    component: LayoutComponent,
    data: {
      layout: 'empty',
    },
    children: [
      {
        path: 'sign-out',
        loadChildren: () =>
          import('app/modules/auth/sign-out/sign-out.module').then(
            m => m.AuthSignOutModule
          ),
      },
    ],
  },

  // Admin routes
  {
    path: '',
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    component: LayoutComponent,
    resolve: {
      initialData: InitialDataResolver,
    },
    children: [
      {
        path: 'farms',
        loadChildren: () =>
          import('app/modules/admin/farms/farms.module').then(
            m => m.FarmsModule
          ),
        canActivate: [AuthRoles],
      },
      {
        path: 'service-orders',
        loadChildren: () =>
          import('app/modules/admin/service-orders/service-orders.module').then(
            m => m.ServiceOrdersModule
          ),
      },
      {
        path: 'reports',
        loadChildren: () =>
          import('app/modules/admin/reports/reports.module').then(
            m => m.ReportsModule
          ),
        canActivate: [AuthRoles],
      },
      {
        path: 'checklists',
        loadChildren: () =>
          import('app/modules/admin/checklists/checklists.module').then(
            m => m.ChecklistsModule
          ),
        canActivate: [AuthRoles],
      },
      {
        path: 'settings',
        loadChildren: () =>
          import('app/modules/settings/settings.module').then(
            m => m.SettingsModule
          ),
        canActivate: [AuthRoles],
      },
      {
        path: 'diaries',
        loadChildren: () =>
          import('app/modules/admin/diaries/diaries.module').then(
            m => m.DiariesModule
          ),
        canActivate: [AuthRoles],
      },
      // {path: 'squads', loadChildren: () => import('app/modules/admin/squads/squads.module').then(m => m.SquadsModule)},
      {
        path: 'dashboard',
        loadChildren: () =>
          import('app/modules/admin/dashboard/dashboard.module').then(
            m => m.DashboardModule
          ),
      },
      {
        path: 'biofactories',
        loadChildren: () =>
          import('app/modules/admin/biofactories/biofactories.module').then(
            m => m.BiofactoriesModule
          ),
      },
      {
        path: 'compatibilidade',
        loadChildren: () =>
          import(
            'app/modules/admin/compatibilidade/compatibilidade.module'
          ).then(m => m.CompatibilidadeModule),
        canActivate: [AuthRoles],
      },
      {
        path: 'ea-protocol',
        loadChildren: () =>
          import('app/modules/admin/ea-protocol/ea-protocol.module').then(
            m => m.EaProtocolModule
          ),
        canActivate: [AuthRoles],
      },
      {
        path: 'time-line',
        loadChildren: () =>
          import('app/modules/admin/timeline/timeline.module').then(
            m => m.TimelineModule
          ),
      },
      {
        path: 'type-simulator',
        loadChildren: () =>
          import('app/modules/admin/type-simulator/type-simulator.module').then(
            m => m.TypeSimulatorModule
          ),
      },
      {
        path: 'simulator',
        loadChildren: () =>
          import('app/modules/admin/simulator/simulator.module').then(
            m => m.SimulatorModule
          ),
      },
      {
        path: 'calendario',
        loadChildren: () =>
          import(
            'app/modules/admin/calendar-aea-aql/calendar-aea-aql.module'
          ).then(m => m.CalendarAeaAqlModule),
      },
      {
        path: 'previsibilidade',
        loadChildren: () =>
          import(
            'app/modules/admin/predictability/predictability.module'
          ).then(m => m.PredictabilityModule),},
          {
        path: 'plan',
        loadChildren: () =>
          import(
            'app/modules/admin/plan/plan.module'
          ).then(m => m.PlanModule),
      },
    ],
  },

  // Landing routes
  {
    path: '',
    component: LayoutComponent,
    data: {
      layout: 'empty',
    },
    children: [
      {
        path: 'privacy',
        loadChildren: () =>
          import(
            'app/modules/landing/privacy-policy/privacy-policy.module'
          ).then(m => m.PrivacyPolicyModule),
      },
    ],
  },
];
