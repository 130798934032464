<div [formGroup]="formGroup">
    <mat-form-field [formGroup]="formGroup" class="flex-auto w-full">
        <mat-label>{{ label }}</mat-label>
        <mat-select [formControlName]="controlName" placeholder="Pesquisar..." #singleSelect>
            <mat-option>
                <ngx-mat-select-search [formControl]="optionServerSideFilteringCtrl" [searching]="searching"
                    placeholderLabel="Procurar {{ label.toLowerCase() }}..."
                    noEntriesFoundLabel="Valor não encontrada"></ngx-mat-select-search>
            </mat-option>
            <mat-option class="mt-2 border-b" *ngFor="let option of filteredServerSideOptions | async"
                [value]="option.value">
                <div class="font-medium leading-5">
                    {{ option.label }}
                </div>
            </mat-option>
        </mat-select>
        <mat-error *ngIf="
                formGroup.get(controlName).touched &&
                formGroup.get(controlName).invalid
            ">
            Este é um campo obrigatório
        </mat-error>
    </mat-form-field>
</div>