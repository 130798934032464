import { AlertModel } from './alert.model';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AlertService {
  private _alert: BehaviorSubject<AlertModel | null> = new BehaviorSubject(
    null
  );

  get alert$(): Observable<AlertModel> {
    return this._alert.asObservable();
  }

  showWarning(title: string, message: string, time?: number): void {
    this._alert.next({
      type: 'warning',
      title: title,
      message: message,
      time: time,
    });
  }

  showInfo(title: string, message: string, time?: number): void {
    this._alert.next({
      type: 'info',
      title: title,
      message: message,
      time: time,
    });
  }

  showError(title: string, message: string, time?: number): void {
    this._alert.next({
      type: 'error',
      title: title,
      message: message,
      time: time,
    });
  }

  showSuccess(title: string, message: string, time?: number): void {
    this._alert.next({
      type: 'success',
      title: title,
      message: message,
      time: time,
    });
  }
}
