import { Regional } from '../../../../core/models/squads/territory.model';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Territory } from 'app/core/models/squads/territory.model';
import { take, takeUntil } from 'rxjs/operators';
import { TerritoryService } from '../../../../core/services/squads/territory.service';
import { Component, OnInit, Inject } from '@angular/core';

@Component({
  selector: 'app-not-visited-farms-modal',
  templateUrl: './not-visisted-farms-modal.component.html',
  styleUrls: ['./not-visisted-farms-modal.component.scss'],
})
export class NotVisistedFarmsModalComponent implements OnInit {
  regionals: Regional[];
  territoryForm: FormGroup;
  editMode: boolean;
  isLoading = true;
  farms: any[];
  title: string;

  constructor(
    public matDialogRef: MatDialogRef<NotVisistedFarmsModalComponent>,
    public matDialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit() {
    this.farms = this.data.farms;
    this.title = this.data.title;
  }

  cancel(): void {
    this.matDialogRef.close();
  }
}
