import { Component, Input } from '@angular/core';
import { CalendarEvent } from 'angular-calendar';

@Component({
  selector: 'app-event-item',
  templateUrl: 'event-item.component.html',
  styleUrls: ['event-item.component.scss'],
})
export class EventItemComponent {
  @Input() public event: CalendarEvent;
  @Input() public customClass: string = '';

  constructor() {}

  public get title(): string {
    return this.event?.title;
  }

  public get status(): string {
    return this.event?.meta?.status;
  }
}
