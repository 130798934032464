import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { ViewPdfComponent } from './view-pdf/view-pdf.component';

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { TextMaskModule } from 'angular2-text-mask';
import { MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import * as moment from 'moment';
import { FarmSearchComponent } from './farm-search/farm-search.component';
import { SearchMultFarmComponent } from './search-mult-farm/search-mult-farm.component';
import { SelectSearchComponent } from './select-search/select-search.component';

import { MatSelectModule } from '@angular/material/select';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { UserSearchComponent } from './user-search/user-search.component';
import { MaxLengthPipe } from './pipes/max-length.pipe';
import { IifPipe } from './pipes/iif.pipe';
import { MatInputModule } from '@angular/material/input';
import { EventItemComponent } from './event-item/event-item.component';

@NgModule({
  declarations: [
    ViewPdfComponent,
    FarmSearchComponent,
    SelectSearchComponent,
    UserSearchComponent,
    SearchMultFarmComponent,
    MaxLengthPipe,
    IifPipe,
    EventItemComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    PdfViewerModule,
    MatIconModule,
    MatButtonModule,
    MatFormFieldModule,
    MatDatepickerModule,
    MatMomentDateModule,
    TextMaskModule,
    MatSelectModule,
    NgxMatSelectSearchModule,
    MatInputModule,
    ReactiveFormsModule,
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FarmSearchComponent,
    SelectSearchComponent,
    UserSearchComponent,
    SearchMultFarmComponent,
    MaxLengthPipe,
    IifPipe,
    EventItemComponent,
  ],
  providers: [
    {
      provide: MAT_DATE_FORMATS,
      useValue: {
        parse: {
          dateInput: moment.ISO_8601,
        },
        display: {
          dateInput: 'DD/MM/YYYY',
          monthYearLabel: 'MMM YYYY',
          dateA11yLabel: 'DD/MM/YYYY',
          monthYearA11yLabel: 'MMMM YYYY',
        },
      },
    },
    { provide: MAT_DATE_LOCALE, useValue: 'pt-BR' },
  ],
})
export class SharedModule {}
