import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'view-pdf',
  templateUrl: './view-pdf.component.html',
})
export class ViewPdfComponent implements OnInit {
  source: string;

  constructor() {}

  ngOnInit(): void {
    this.source =
      'https://vadimdez.github.io/ng2-pdf-viewer/assets/pdf-test.pdf';
  }
}
