import { JobTitle } from '../../models/squads/jobtitle.model';
import { FuseConfigService } from '../../../../@fuse/services/config/config.service';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, ReplaySubject, BehaviorSubject, of } from 'rxjs';
import { tap, take } from 'rxjs/operators';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root',
})
export class JobTitleService {
  private host: string;
  private titles: JobTitle[] = [];
  private titlesSquad: JobTitle[] = [];

  constructor(
    private _httpClient: HttpClient,
    private _fuseConfigService: FuseConfigService
  ) {
    this._fuseConfigService.config$.subscribe(config => {
      this.host = environment.custHost;
    });
  }

  getTitles(): Observable<JobTitle[]> {
    if (this.titles.length > 0) {
      return of(this.titles);
    }
    return this._httpClient
      .get<JobTitle[]>(this.host + '/api/v1/squads/jobtitle')
      .pipe(
        take(1),
        tap(titles => {
          this.titles = titles;
        })
      );
  }

  // Get Titles feito especialmente para squads removendo os title do jobtitles que não são usados no cadastro de squads

  getTitlesSquad(): Observable<JobTitle[]> {
    if (this.titlesSquad.length > 0) {
      return of(this.titlesSquad);
    }
    return this._httpClient
      .get<JobTitle[]>(this.host + '/api/v1/squads/jobtitle')
      .pipe(
        take(1),
        tap(titles => {
          this.titlesSquad = titles;
          for (let title in this.titlesSquad) {
            if (
              parseInt(this.titlesSquad[title].id) != 2 &&
              parseInt(this.titlesSquad[title].id) != 3 &&
              parseInt(this.titlesSquad[title].id) != 4 &&
              parseInt(this.titlesSquad[title].id) != 6 &&
              parseInt(this.titlesSquad[title].id) != 7 &&
              parseInt(this.titlesSquad[title].id) != 8
            ) {
              this.titlesSquad.splice(parseInt(title), 1);
            }
          }
        })
      );
  }
}
