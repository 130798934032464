<div class="flex flex-col max-w-240 md:min-w-160 -m-6">
    <!-- Header -->
    <div
        class="flex flex-0 items-center justify-between h-16 pr-3 sm:pr-5 pl-6 sm:pl-8 bg-primary text-on-primary">
        <div class="text-lg font-medium">Visualizar PDF</div>
        <button mat-icon-button [tabIndex]="-1">
            <!-- <mat-icon
                class="text-current"
                [svgIcon]="'heroicons_outline:x'"></mat-icon> -->
        </button>
    </div>

    <!-- Compose form -->
    <div class="flex flex-col flex-auto p-6 sm:p-8 overflow-y-auto">
        Aqui vai o PDF
        <pdf-viewer
            [src]="source"
            [render-text]="false"
            [fit-to-page]="false"
            [show-borders]="true"
            [show-all]="false"></pdf-viewer>
    </div>

    <!-- Actions -->
    <div
        class="flex flex-col sm:flex-row sm:items-center justify-between mt-4 sm:mt-6">
        <div class="-ml-2"></div>

        <div class="flex items-center mt-4 sm:mt-0">
            <!-- Save as draft -->
            <button class="sm:mx-3" mat-button>
                <span>Cancelar</span>
            </button>
            <!-- Send -->
            <!-- <button
                    class="order-first sm:order-last"
                    mat-flat-button
                    [color]="'primary'">
                    Salvar
                </button> -->
        </div>
    </div>
</div>
