import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'iif',
})
export class IifPipe implements PipeTransform {
  transform<TTrue, TFalse>(
    value: boolean,
    results: [TTrue, TFalse]
  ): TTrue | TFalse {
    return value ? results[0] : results[1];
  }
}
