import { DomSanitizer } from '@angular/platform-browser';
import { UserService } from 'app/core/services/auth/user.service';
import { User } from 'app/core/models/auth/user.model';
import { UsersService } from 'app/core/services/admin/users.service';
import {
  filter,
  tap,
  takeUntil,
  debounceTime,
  map,
  delay,
  take,
} from 'rxjs/operators';
import { FormControl, FormGroup } from '@angular/forms';
import { MatSelect } from '@angular/material/select';
import {
  Component,
  OnInit,
  Input,
  ViewChild,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { Subject, ReplaySubject } from 'rxjs';
@Component({
  selector: 'user-search',
  templateUrl: './user-search.component.html',
})
export class UserSearchComponent implements OnInit, OnChanges {
  // @Input() defaultClass: string; //class="flex-auto gt-xs:pr-3"
  @Input() public label: string;
  @Input() public controlName: string;
  @Input() public formGroup: FormGroup;
  @Input() public filterJobtitle: number[];
  @Input() public showNone = false;

  public defaultClass = 'flex-auto gt-xs:pr-3';

  @ViewChild('singleSelectUser') singleSelectUser: MatSelect;

  public userServerSideFilteringCtrl: FormControl = new FormControl();

  /** indicate search operation is in progress */
  public searching: boolean = false;

  /** list of banks filtered after simulating server side search */
  public filteredServerSideUsers: ReplaySubject<User[]> = new ReplaySubject<
    User[]
  >(1);

  /** Subject that emits when the component has been destroyed. */
  protected _onDestroy = new Subject<void>();

  /** list of Farms */
  protected users: User[] = [];

  constructor(
    private _usersService: UsersService,
    private _userService: UserService
  ) {}

  ngOnInit(): void {
    this._usersService
      .getUsersMemory()
      .pipe(take(1))
      .subscribe((users: User[]) => {
        if (this.filterJobtitle == null) {
          this.users = users;
        } else {
          this.users = users.filter(u =>
            this.filterJobtitle.includes(parseInt(u.job_type, 10))
          );
        }

        this.filteredServerSideUsers.next(this.users.slice());

        this.setInitialValue();
      });

    // listen for search field value changes
    this.userServerSideFilteringCtrl.valueChanges
      .pipe(
        //				filter((search) => !!search),
        tap(() => {
          this.searching = true;
        }),
        takeUntil(this._onDestroy),
        debounceTime(200),
        map(search => {
          if (!this.users) {
            return [];
          }
          // TODO: change to call api
          search = search.toLowerCase();
          const filtered = this.users.filter(
            user =>
              search == '' ||
              user.firstname.toLowerCase().includes(search) ||
              user.lastname?.toLowerCase().includes(search) ||
              (
                user.firstname?.toLowerCase() +
                ' ' +
                user.lastname?.toLowerCase()
              ).includes(search)
          );
          return filtered;
        }),
        delay(500),
        takeUntil(this._onDestroy)
      )
      .subscribe(
        filteredUsers => {
          this.searching = false;
          this.filteredServerSideUsers.next(filteredUsers);
        },
        error => {
          // no errors in our simulated example
          this.searching = false;
          // handle error...
        }
      );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.filterJobtitle?.currentValue) {
      this.filterJobtitle = changes.filterJobtitle.currentValue;
      this._usersService
        .getUsersMemory()
        .pipe(take(1))
        .subscribe((users: User[]) => {
          this.users = this.filterJobtitle
            ? users.filter(u =>
                this.filterJobtitle.includes(parseInt(u.job_type, 10))
              )
            : users;
          this.filteredServerSideUsers.next(this.users.slice());
        });
    }
  }

  /**
   * Sets the initial value after the filteredBanks are loaded initially
   */
  protected setInitialValue(): void {
    this.filteredServerSideUsers
      .pipe(take(1), takeUntil(this._onDestroy))
      .subscribe(() => {
        // setting the compareWith property to a comparison function
        // triggers initializing the selection according to the initial value of
        // the form control (i.e. _initializeSelection())
        // this needs to be done after the filteredBanks are loaded initially
        // and after the mat-option elements are available
        //this.singleSelect.compareWith = (a: number, b: number) => a && b && a === b;
      });
  }

  public getImage(photo): any {
    return this._userService.getImage(photo);
  }

  public onSelect(value) {}
}
