import { UserService } from 'app/core/services/auth/user.service';
import { FuseNavigationItem } from './../../../../../@fuse/components/navigation/navigation.types';
import { AlertService } from './../../../../core/alert/alert.service';
import { AlertModel } from './../../../../core/alert/alert.model';
import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject, timer } from 'rxjs';
import { takeUntil, map } from 'rxjs/operators';
import { FuseMediaWatcherService } from '@fuse/services/media-watcher';
import {
  FuseNavigationService,
  FuseVerticalNavigationComponent,
} from '@fuse/components/navigation';
import { Navigation } from 'app/core/navigation/navigation.types';
import { NavigationService } from 'app/core/navigation/navigation.service';
import { meanBy } from 'lodash';

@Component({
  selector: 'compact-layout',
  templateUrl: './compact.component.html',
  encapsulation: ViewEncapsulation.None,
})
export class CompactLayoutComponent implements OnInit, OnDestroy {
  isScreenSmall: boolean;
  navigation: Navigation;
  formFieldHelpers: string[] = [''];
  alert: AlertModel;

  private _unsubscribeAll: Subject<any> = new Subject<any>();

  /**
   * Constructor
   */
  constructor(
    private _activatedRoute: ActivatedRoute,
    private _router: Router,
    private _navigationService: NavigationService,
    private _fuseMediaWatcherService: FuseMediaWatcherService,
    private _fuseNavigationService: FuseNavigationService,
    private _alertService: AlertService,
    private _userService: UserService
  ) {}

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  /**
   * Getter for current year
   */
  get currentYear(): number {
    return new Date().getFullYear();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    this._userService.user$.subscribe(u => {
      this._navigationService.get(u.roles);
    });

    // Subscribe to navigation data
    this._navigationService.navigation$
      .pipe(
        takeUntil(this._unsubscribeAll),
        map(menu => menu)
      )
      .subscribe((navigation: Navigation) => {
        this.navigation = navigation;
      });

    // Subscribe to media changes
    this._fuseMediaWatcherService.onMediaChange$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(({ matchingAliases }) => {
        // Check if the screen is small
        this.isScreenSmall = !matchingAliases.includes('md');
      });

    this._alertService.alert$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((alert: AlertModel) => {
        if (alert) {
          this.showAlert({
            type: alert.type,
            title: alert.title,
            message: alert.message,
            time: alert.time,
          });
        }
      });
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Toggle navigation
   *
   * @param name
   */
  toggleNavigation(name: string): void {
    // Get the navigation
    const navigation =
      this._fuseNavigationService.getComponent<FuseVerticalNavigationComponent>(
        name
      );

    if (navigation) {
      // Toggle the opened status
      navigation.toggle();
    }
  }

  /**
   * Get the form field helpers as string
   */
  getFormFieldHelpersAsString(): string {
    return this.formFieldHelpers.join(' ');
  }

  showAlert(a: AlertModel): void {
    // Show a success message (it can also be an error message)
    // and remove it after 5 seconds
    this.alert = {
      type: a.type,
      title: a.title,
      message: a.message,
    };

    setTimeout(() => {
      this.alert = null;
    }, a.time || 5000);
  }

  public navigate(route): void {
    this._router.navigate([route]);
  }
}
