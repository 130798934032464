<div class="flex flex-col max-w-240 md:min-w-160 -m-6 modal-container">
    <!-- Header -->
    <div
        class="flex flex-0 items-center justify-between h-16 pr-3 sm:pr-5 pl-6 sm:pl-8 bg-primary text-on-primary">
        <div class="text-lg font-medium">{{ title }}</div>
        <button mat-icon-button (click)="cancel()" [tabIndex]="-1">
            <mat-icon
                class="text-current"
                [svgIcon]="'heroicons_outline:x'"></mat-icon>
        </button>
    </div>

    <div
        class="shadow text-md font-semibold text-secondary bg-gray-50 dark:bg-black dark:bg-opacity-5 farm-list-header">
        <div class="truncate flex-1 text-center">Hora</div>
        <div class="truncate flex-1 text-center">ID Visita</div>
        <div class="truncate">Nome da Fazenda</div>
        <div class="truncate">Nome do Proprietário</div>
    </div>

    <div *ngFor="let item of diaries">
        <span
            class="farm-list-date shadow text-md font-semibold text-secondary bg-gray-50"
            >{{ formatDate(item.date) }}</span
        >
        <ng-container *ngFor="let diary of item.diaries">
            <div
                class="farm-list-item cursor-pointer"
                (click)="openDetails(diary)">
                <div class="truncate flex-1 text-center">
                    {{ formatTime(diary.date) }}
                </div>

                <div class="truncate flex-1 text-center">
                    {{ diary.id }}
                </div>

                <div class="truncate">
                    {{ diary.farm.farm_name }}
                </div>

                <div class="truncate">
                    {{ diary.farm.company_name }}
                </div>
            </div>
        </ng-container>
    </div>
</div>
